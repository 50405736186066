.vjs-theme-city {
  --vjs-theme-city--primary:#bf3b4d {}
  --vjs-theme-city--secondary:#fff {}

  .vjs-control-bar {
    height: 70px;
    padding-top: 20px;
    background: none;
    background-image: linear-gradient(0deg, #000, transparent); }

  .vjs-button > .vjs-icon-placeholder:before {
    line-height: 50px; }

  .vjs-play-progress:before {
    display: none; }

  .vjs-progress-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 20px;

    .vjs-progress-holder {
      position: absolute;
      top: 20px;
      right: 0;
      left: 0;
      width: 100%;
      margin: 0; } }

  .vjs-play-progress {
    background-color: var(--vjs-theme-city--primary); }

  .vjs-remaining-time {
    order: 1;
    line-height: 50px;
    flex: 3;
    text-align: left; }

  .vjs-play-control {
    order: 2;
    flex: 8;
    font-size: 1.75em; }

  .vjs-fullscreen-control, .vjs-picture-in-picture-control {
    order: 3;
    flex: 1; }

  .vjs-volume-panel {
    order: 3;
    flex: 1;

    &:hover .vjs-volume-control.vjs-volume-horizontal {
      height: 100%; } }

  .vjs-mute-control {
    display: none; }

  .vjs-volume-panel {
    margin-left: .5em;
    margin-right: .5em;
    padding-top: 1.5em; }

  .vjs-volume-bar.vjs-slider-horizontal {
    width: 3em; }

  .vjs-volume-panel {
    width: 3em;

    &.vjs-volume-panel-horizontal:hover, &:active .vjs-volume-control.vjs-volume-horizontal, &:focus .vjs-volume-control.vjs-volume-horizontal {
      width: 3em; }

    &:hover {
      width: 3em;

      .vjs-volume-control.vjs-volume-horizontal {
        width: 3em; } } }

  .vjs-volume-level:before {
    font-size: 1em; }

  .vjs-volume-panel .vjs-volume-control {
    opacity: 1;
    width: 100%;
    height: 100%; }

  .vjs-volume-bar {
    background-color: transparent;
    margin: 0; }

  .vjs-slider-horizontal .vjs-volume-level {
    height: 100%; }

  .vjs-volume-bar {
    &.vjs-slider-horizontal {
      margin-top: 0;
      margin-bottom: 0;
      height: 100%; }

    &:before {
      content: "";
      z-index: 0;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-color: transparent transparent hsla(0, 0%, 100%, 0.25);
      border-style: solid;
      border-width: 0 0 1.75em 3em; } }

  .vjs-volume-level {
    overflow: hidden;
    background-color: transparent;

    &:before {
      content: "";
      z-index: 1;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-left: 3em solid transparent;
      border-bottom: 1.75em solid var(--vjs-theme-city--secondary);
      border-right: 0 solid transparent;
      border-top: 0 solid transparent; } } }
.vjs_video_3-dimensions {
	width: 100%;
	height: auto; }
