
// ==========
// = Vars
// ==========
$spacer: 20px;
$time: 0.3s;

$full: 1200px;
$desktop: 1199px;
$tablet: 1019px;
$phablet: 730px;
$mobile: 376px;

// ==========
// = Font
// ==========
@font-face {
  font-family: 'Adieu';
  font-weight: 300;
  src: url('../fonts/adieu-light.otf'); }
@font-face {
  font-family: 'Adieu';
  font-weight: 400;
  src: url('../fonts/adieu-regular.otf'); }
@font-face {
  font-family: 'Adieu';
  font-weight: 600;
  src: url('../fonts/adieu-bold.otf'); }
@font-face {
  font-family: 'Adieu';
  font-weight: 900;
  src: url('../fonts/adieu-black.otf'); }



// ==========
// = Mixins
// ==========

// Breakpoints
@mixin breakpoint($point) {
  @if $point == full {
    @media (min-width: $full) {
      @content; } }

  @else if $point == desktop {
    @media (max-width: $desktop) {
      @content; } }

  @else if $point == tablet {
    @media (max-width: $tablet) {
      @content; } }

  @else if $point == phablet {
    @media (max-width: $phablet) {
      @content; } }

  @else if $point == mobile {
    @media (max-width: $phablet) {
      @content; } }
  @else {
    @media (max-width: $point) {
      @content; } } }

// Center
@mixin center($xy:xy) {
  @if $xy == xy {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%); }

  @else if $xy == x {
    position: absolute;
    left: 50%;
    right: auto;
    transform: translateX(-50%); }

  @else if $xy == y {
    position: absolute;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%); } }

// ==========
// = Silent
// ==========
%flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
