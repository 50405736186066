// ============================
// = Imports
// ============================
@import "includes/reset";
@import "includes/init";
//@import "includes/fontawesome-all"
@import "includes/video-landia";
// ------------------------------

$darkBackgroundColor: #161616;
$lightBackgroundColor: #f7f7f7;

.strong, strong {
  font-weight: 700; }

input, textarea {
  appearance: none;
  resize: none;
  &::placeholder {
    font-size: 11px;
    line-height: 1.3;
    color: white;
    @include breakpoint(mobile) {
      font-size: 11.5px;
      padding-top: 5px; } } }



// ============================
// = Scroll magnetico
// ============================
@media (hover: hover) {
  html, body {
    scroll-snap-type: y mandatory;
    scroll-padding: 0px 0px 0px 0px;
    position: relative;
    overscroll-behavior: none;
    .director, .reel, header, footer, .newsletter, .reel-grid, .reel-block, .attachment-block {
      scroll-snap-align: start;
      scroll-snap-stop: always; } }

  .bottom-logo {
    @include breakpoint(mobile) {
      scroll-snap-align: start;
      scroll-snap-stop: always; } } }

// ============================
// = General
// ============================
body {
  font-weight: 300;
  overflow-x: hidden;
  * {
    box-sizing: border-box; } }


// Hack para los espacios blancos del tema
body >div:first-of-type >p, body >div:first-of-type >br {
  display: none; }

// Container
.container, %container {
  width: 100%;
  padding: 0 100px;
  @include breakpoint(tablet) {
    padding: 0 20px; } }

p {
  padding-bottom: $spacer*1.5;
  line-height: 1.3;
  font-size: 1.1em; }

body {
  font-family: 'Adieu';
  -webkit-font-smoothing: antialiased;
  background: $darkBackgroundColor;
  color: white;
  .icon-landia svg {
    fill: white; }
  .logo-landia {
    .st0 {
      fill: white; }
    .st1 {
      fill: none;
      stroke: white;
      stroke-width: 4;
      stroke-miterlimit: 10; } }
  header, .reel-header {
    background: $darkBackgroundColor;
    z-index: 999999; }
  header {
    border-bottom: 1px solid white; }
  .reel-header {
    border-top: 1px solid white; } }



// ============================
// = Header
// ============================
header, .reel-header {
  padding: 8px 0px;
  display: flex;

  @include breakpoint(tablet) {
    p, h2 {
      font-size: .9em; } }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    position: relative;
    @include breakpoint(mobile);
    p {
      height: auto; } }

  .header-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $spacer; }

  .icon-landia svg {
    height: 35px;
    width: auto; }
  p {
    padding-bottom: 0; }

  @include breakpoint(tablet) {
    justify-content: flex-start;
    align-items: center; } }

// Solo en el header
header {
  .header-text {
    @include breakpoint(tablet) {
      display: block; } } }

// Solo en el header del reel
.reel-header {
  .header-text {
    padding-right: 0;
    p {
      padding-right: 10px;
      font-size: .75em;
      letter-spacing: 2px; }
    h2 {
      line-height: 1.3; } }
  @include breakpoint(tablet) {
    .icon-landia {
      display: none; }
    .header-text {
      p {
        font-size: .6em;
        min-width: 110px;
        text-align: right;
        padding-right: 0; } } }
  @include breakpoint(mobile) {
    .header-text {
      align-items: flex-start;
      p {
        padding-top: 6px;
        font-size: .5em;
        letter-spacing: 0px; } } } }

// ============================
// = Hero
// ============================
.video-hero {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  // position: fixed
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  @include breakpoint(mobile) {
    height: 50vh; } }
.hero {
  background-position: center center;
  background-size: cover;
  position: relative;
  height: calc(100vh - 60px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend %container;
  @include breakpoint(mobile) {
    height: calc(50vh - 77px); }
  svg {
    width: 100%;
    height: auto;
    background: none;
    .st0 {
      fill:#fff {} }
    .st1 {
      fill: none;
      stroke: #fff;
      stroke-width: 4;
      stroke-miterlimit: 10; } } }




// ============================
// = Director
// ============================

.director {
  background: $darkBackgroundColor;
  position: relative;
  border-bottom: 1px solid white;
  h1 {
    font-size: 5.5vw;
    font-weight: 900;
    padding: 30px 0 40px 0;
    line-height: 1;
    position: relative;
    @include breakpoint(tablet) {
      padding: 30px 90px 40px 0;
      .btn-round {
        right: 0;
        transform: scale(.8) translateY(-69%); } }

    @include breakpoint(mobile) {
      font-size: 11.5vw;
      .btn-round {
        right: 0;
        top: 58px;
        transform: scale(.8) translateY(-69%); } } } }

.section-spacer {
  height: 100px;
  background: $darkBackgroundColor;
  @include breakpoint(mobile) {
    display: none; } }
.profile {
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 150px - 100px);
  color: white;
  @include breakpoint(729px) {
    height: 0px; }
  .profile-description {
    padding: $spacer*2 0;
    background-color: rgba(0,0,0,.7);
    height: 0;
    position: relative;
    opacity: 0;
    p {
      width: 60%;
      // max-width: 700px
      // font-size: .95em
      font-size: 1.1vw;
      line-height: 1.4; }
    &.show {
      height: calc(100vh - 150px - 100px);
      opacity: 1;
      transition: all, 0.3s;
      @include breakpoint(tablet) {
        position: absolute;
        z-index: 99999999;
        background-color: rgba(0,0,0,.9);
        height: auto;
        p {
          width: 100%;
          font-size: 0.9em; } } } } }

.btn-round {
  width: 84px;
  height: 68px;
  margin-left: 30px;
  border-radius: 25px;
  background-color: $darkBackgroundColor;
  position: relative;
  border: 2px solid white;
  cursor: pointer;
  padding: 20px 28px;
  @include center(y);

  &:hover {
    background-color: $darkBackgroundColor; }
  &:focus {
    outline: none; }
  &.cross {
    .close:after {
      // se convierte en -
      transform: rotate(90deg) translateX(-420%); } }

  @include breakpoint(tablet) {
    position: absolute; } }

.close {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all, 0.3s;

  &::before, &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    transition: all, 0.3s; }

  &:before {
    // transform: rotate(0deg)
    height: 3px;
    width: 100%;
    @include center; }
  &:after {
    // transform: rotate(90deg)
    height: 100%;
    width: 3px;
    @include center; } }


// ============================
// = Reel
// ============================
.reel {
  padding-bottom: 100px;
  background: $darkBackgroundColor;
  @include breakpoint(mobile) {
    padding-bottom: 20px; } }
.reel-video {
  max-height: calc(100vh - 100px - 77px);
  overflow: hidden;
  @include breakpoint(mobile) {
    max-height: calc(100vh - 100px - 20px); }

 }  // Otro hack
.reel-video-playing {
  @include breakpoint(full) {
    .click-dimensions.vjs-fluid {
      padding: 0 !important;
      height: 100vh; }
    .video-js .vjs-fluid {
      height: 100%; } }
  @include breakpoint(desktop) {
    .click-dimensions.vjs-fluid {
      // padding: 0 !important
      height: 100vh; }
    .video-js .vjs-fluid {
      height: 100%; } } }

// Hack para el pausado
.reel-video-paused {
  @include breakpoint(full) {
    .video-js .vjs-tech {
      top: -18%; } }
  @include breakpoint(desktop) {
    .video-js .vjs-tech {
      top: -10%; } }
  @include breakpoint(tablet) {
    .video-js .vjs-tech {
      top: 0; } } }





.video-js {
  &.focus {
    outline: none; }
  .vjs-control-bar {
    background: rgba(0,0,0,.5); }
  .vjs-load-progress {
    background: none;
    div {
      background: rgba(255,255,255,.5); } } }



// ============================
// = Reel Grid
// ============================
.reel-grid {
  background: $darkBackgroundColor;
  border-top: 1px solid white;
  position: relative;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  @include breakpoint(tablet) {
    display: none; }
  .reel-block {
    h3 {
      text-align: center;
      padding: $spacer;
      font-size: .9em;
      opacity: 0;
      position: absolute;
      @include center;
      transition: all .3s;
      line-height: 1.5;
      width: 80%;
      .strong, strong {
        display: block; } }

    a {
      position: relative;
      display: block;
      max-height: 35vh;
      overflow: hidden;
      color: white;
      &::after {
        content: "";
        background: rgba(0, 0, 0, 0);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: all .3s; }

      &:hover {
        &::after {
          background: rgba(0, 0, 0, 0.7); }
        h3 {
          opacity: 1;
          z-index: 99; } }
      img {
        display: block;
        width: 100%;
        height: auto; } } }
  &.columns-one-third {
    .reel-block {
      width: 33.33%; } }
  &.columns-one-half {
    .reel-block {
      width: 50%; } }
  &.columns-one-quarter {
    .reel-block {
      width: 25%; } } }

// ============================
// = Attachment Grid
// ============================
.attachment-grid {
  background: $darkBackgroundColor;
  position: relative;
  z-index: 9;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  border-bottom: 1px solid white;
  .attachment-block {
    width: 33.33%;
    padding-bottom: 40px;
    @include breakpoint(tablet) {
      width: 100%; }
    h3 {
      text-align: center;
      padding: $spacer; }
    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 200px;
      margin: 0 auto; } }
  @include breakpoint(tablet) {
    display: block; } }

// ============================
// = Logo bottom
// ============================
.bottom-logo, .footer-post {
  background: $darkBackgroundColor;
  .icon-landia {
    padding: 80px 0 70px 0;
    text-align: center;
    svg {
      height: 70px;
      width: auto; }
    @include breakpoint(mobile) {
      padding: 30px 0 20px 0;
      svg {
        height: 40px; } } } }

// ============================
// = Footer
// ============================
footer {
  background: $darkBackgroundColor;
  text-align: center;
  .container {
    max-width: 880px;
    margin: 0 auto;
    h3 {
      font-size: 1.55em;
      padding-top: 80px;
      margin-bottom: $spacer/2; }
    h4 {
      font-size: 2.5em;
      font-weight: 900;
      margin-bottom: 170px; }
    @include breakpoint(mobile) {
      // max-width: 80%
      h3 {
        font-size: 1.3em;
        padding-top: 20px; }
      h4 {
        font-size: 1.8em;
        margin-bottom: 50px; } } }
  .logo-landia {
    .st1 {
      fill: white; } } }


// ============================
// = Newsletter
// ============================
.newsletter {
  background: $darkBackgroundColor;
  padding-top: 150px;
  padding-bottom: 100px;
  .container {
    @extend %container;
    max-width: 880px;
    margin: 0 auto;
    @include breakpoint(mobile) {
 }      // max-width: 80%
    h3 {
      font-size: 1.5em;
      font-weight: 700; }
    form {
      display: flex;
      justify-content: space-between;
      @include breakpoint(tablet) {
        display: block; } }
    input, textarea {
      background: none;
      border: none;
      border-bottom: 1px solid white;
      padding: $spacer 0;
      width: 64%;
      font-size: 14px;
      color: white;
      height: 55px;
      border-radius: 0;
      font-family: 'Adieu';
      font-weight: 300;
      @include breakpoint(tablet) {
        width: 100%; }
      &:focus {
        outline: none;
        border-bottom: 3px solid white; } }
    button {
      background: none;
      border-radius: 0;
      border: 1px solid white;
      color: white;
      // padding: $spacer
      width: 33%;
      height: 55px;
      text-transform: uppercase;
      @include breakpoint(tablet) {
        width: 100%;
        margin-top: $spacer; } } } }

// ============================
// = Diseño 2
// ============================
.design-2 {
  .hero {
    height: calc(50vh - 60px);
    @include breakpoint(mobile) {
      height: calc(50vh - 77px); }
    svg {
      .st0 {
        fill: white; }
      .st1 {
        fill: white;
        stroke: white; } } }

  &.light-mode {
    .hero {
      svg {
        .st0 {
          fill: black; }
        .st1 {
          fill: black;
          stroke: black; } } } } }
// ============================
// = Player
// ============================
.video-js .vjs-big-play-button {
  display: none !important; }

.video-js {
  .vjs-control-bar {
    background: rgba(0, 0, 0, .5) !important; }
  .vjs-slider {
    background: rgba(255,255,255, .3) !important; } }



// ============================
// = Light Mode
// ============================
.light-mode {
  background: $lightBackgroundColor;
  color: black;
  .icon-landia svg {
    fill: black; }
  .attachment-grid {
    background: $lightBackgroundColor;
    border-bottom: 1px solid black; }
  header, .reel-header {
    background: $lightBackgroundColor; }
  header {
    border-bottom: 1px solid black; }
  .director {
    background: $lightBackgroundColor; }
  .section-spacer {
    background: $lightBackgroundColor; }
  .btn-round {
    background-color: $lightBackgroundColor;
    border: 4px solid black; }
  .close::before, .close::after {
    background: black; }
  .reel-header {
    border-top: 1px solid black; }
  .reel {
    background: $lightBackgroundColor; }
  .reel-grid {
    background: $lightBackgroundColor;
    border-top: 1px solid black; }
  input, textarea {
    &::placeholder {
      color: black; } }
  .bottom-logo, footer, .newsletter, .footer-post {
    background: $lightBackgroundColor;
    color: black; }
  .newsletter .container {
    input, textarea {
      border-bottom: 1px solid black;
      color: black;
      &::placeholder {
        color: black; } } }
  .newsletter .container button {
    border: 1px solid black;
    color: black; }
  footer {
    .logo-landia {
      .st0 {
        fill: black; }
      .st1 {
        fill: black;
        stroke: black; } } } }

// ============================
// = Post
// ============================
.logo-landia-post {
  svg {
    width: 200px;
    height: auto;
    fill: white;
    padding: 10px 0; } }
.project-title {
  @include center; }

.content {
  color: white;
  padding-top: 40px;
  @extend %container; }



// ============================
// = PLAIN TEMPLATE
// ============================
.plain-reel {
  a:hover {
    text-decoration: none; }
  .container {
    padding: 0 65px;
    @include breakpoint(mobile) {
      padding: 0; } }

  .header {
    @include breakpoint(mobile) {
      border-bottom: none;
      padding-bottom: 0; }
    .container {
      flex-wrap: wrap; }
    @include breakpoint(mobile) {
      text-align: center;
      .icon-landia {
        display: none; } } }
  .header-text {
    width: calc(75% - 35px);
    @include breakpoint(mobile) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      p, h1 {
        width: 100%;
        padding: $spacer/2 0; }
      h1 {
        order: -1; }
      p {
        font-size: .7em !important; }
      span {
        font-size: .4em !important; } }
    h1 {
      font-size: 1.3em; }
    p {
      text-transform: uppercase;
      font-size: .6em;
      color: #666;
      a {
        color: #666;
        letter-spacing: 1px;
        padding: 3px;
        position: relative;
        span {
          position: absolute;
          background: white;
          top: 100%;
          @include center(x);
          padding: 5px;
          border-radius: 3px;
          color: black;
          z-index: 999;
          opacity: 0;
          transition: opacity .3s;
          &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #fff transparent; }
          &.active {
            opacity: 1; } } } } }


  .top-logo {
    display: flex;
    align-items: center;
    width: 25%;
    @include breakpoint(mobile) {
      width: 100%;
      border-bottom: 1px solid white; }
    svg {
      height: 35px;
      width: auto;
      margin-right: 50px;
      max-width: 80%;
      @include breakpoint(mobile) {
        margin: 0 auto;
        height: auto;
        width: 70%;
        padding: $spacer/2 0 $spacer 0; }
      .st0 {
        fill:#fff {} }
      .st1 {
        fill: #fff; } } } }



.reels-container {
  display: flex;
  height: calc( 100vh - 60px );
  padding-top: 30px !important;
  overflow: hidden;
  justify-content: space-between;
  @include breakpoint(mobile) {
    flex-wrap: wrap;
    height: auto;
    padding-top: 10px !important; }
  .reel-list {
    width: 14%;
    min-width: 160px;
    height: calc( 100vh - 190px );
    overflow: hidden;
    position: relative;
    @include breakpoint(tablet) {
      min-width: 1px; }
    @include breakpoint(mobile) {
      width: 100%;
      height: auto;
      flex-grow: stretch;
      span {
        display: block; } }
    &::after {
      content: '';
      background: linear-gradient(to top,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: -10px; }
    h2 {
      margin-bottom: 20px;
      font-size: .8em;
      letter-spacing: 1px;
      position: relative;
      display: flex;
      @include breakpoint(mobile) {
        display: none; }
      svg {
        position: relative;
        margin-left: 3px;
        top: -3px; } }
    ul {
      height: calc( 100vh - 220px );
      overflow: auto;
      counter-reset: items;
      @include breakpoint(tablet) {
        height: 200px; }
      &::-webkit-scrollbar {
        width: 12px; }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background: #474747; }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $darkBackgroundColor;
        border: 3px solid #474747; }
      li {
        margin-bottom: 10px;
        margin-right: 10px;
        @include breakpoint(tablet) {
          margin: 0; }
        &:last-of-type {
          margin-bottom: 0; }
        counter-increment: items; }
      img {
        opacity: .5; }
      a {
        color: #4a4a4a;
        font-weight: 600;
        font-size: 1em;
        &.active {
          color: white;
          img {
            border: 1px solid white;
            opacity: 1; } }

        @include breakpoint(mobile) {
          display: flex;
          align-items: center;
          img {
            width: 100px; } }

        @include breakpoint(mobile) {
          &.active {
            img {
              opacity: 1;
              outline: 0; } } } }
      span {
        display: none;
        @include breakpoint(mobile) {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 10px; }
        &::before {
          content: counter(items) ". "; } } }


    .arrow-down {
      height: 20px;
      fill: #FFF; } }
  .reel-carousel {
    width: 75%;
    position: relative;
    height: calc( 100vh - 190px );
    @include breakpoint(mobile) {
      order: -1;
      width: 100%;
      height: auto;
      #theCarousel {
        display: none; } }

    h2 {
      text-align: right;
      padding-top: 20px;
      // position: absolute
      // bottom: -30px
 } } }      // right: 0

.the-player {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: calc( 100vh - 190px ); }
video, .video-js {
  width: 100%;
  height: calc( 100vh - 190px );
  position: absolute;
  background: $darkBackgroundColor; }
.vjs-poster {
  background-size: cover !important; }
.video-js + img {
  display: none !important; }

@include breakpoint(full) {
  .vjs-has-started .vjs-control-bar {
    display: none !important; }

  .vjs-has-started:hover .vjs-control-bar {
    display: flex !important; } }



a.active {
  color: white !important; }
.plain-profile {
  height: 0;
  opacity: 0;
  background: rgba(0,0,0, .7);
  padding: 40px 50px;
  position: absolute;
  z-index: 999999;
  transition: all, .3s;
  overflow: hidden;
  top: 0;


  &.show {
    height: 100%;
    opacity: 1; }
  p {
    font-size: 1.15vw;
    line-height: 1.6;
    max-width: 70%; }
  @include breakpoint(mobile) {
    p {
      font-size: .8em;
      max-width: 100%; }
    &.show {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 12px; }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background: #474747; }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $darkBackgroundColor;
        border: 3px solid #474747; } } } }
*:focus {
  outline: none !important; }


.video-mobile {
  display: none;
  width: 100%;
  @include breakpoint(mobile) {
    display: block; } }
