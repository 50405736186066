/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'Adieu';
  font-weight: 300;
  src: url("../fonts/adieu-light.otf"); }

@font-face {
  font-family: 'Adieu';
  font-weight: 400;
  src: url("../fonts/adieu-regular.otf"); }

@font-face {
  font-family: 'Adieu';
  font-weight: 600;
  src: url("../fonts/adieu-bold.otf"); }

@font-face {
  font-family: 'Adieu';
  font-weight: 900;
  src: url("../fonts/adieu-black.otf"); }

.vjs-theme-city {
  --vjs-theme-city--primary:#bf3b4d {}
  --vjs-theme-city--secondary:#fff {}

  .vjs-control-bar {
    height: 70px;
    padding-top: 20px;
    background: none;
    background-image: linear-gradient(0deg, #000, transparent); }

  .vjs-button > .vjs-icon-placeholder:before {
    line-height: 50px; }

  .vjs-play-progress:before {
    display: none; }

  .vjs-progress-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 20px;

    .vjs-progress-holder {
      position: absolute;
      top: 20px;
      right: 0;
      left: 0;
      width: 100%;
      margin: 0; } }

  .vjs-play-progress {
    background-color: var(--vjs-theme-city--primary); }

  .vjs-remaining-time {
    order: 1;
    line-height: 50px;
    flex: 3;
    text-align: left; }

  .vjs-play-control {
    order: 2;
    flex: 8;
    font-size: 1.75em; }

  .vjs-fullscreen-control, .vjs-picture-in-picture-control {
    order: 3;
    flex: 1; }

  .vjs-volume-panel {
    order: 3;
    flex: 1;

    &:hover .vjs-volume-control.vjs-volume-horizontal {
      height: 100%; } }

  .vjs-mute-control {
    display: none; }

  .vjs-volume-panel {
    margin-left: .5em;
    margin-right: .5em;
    padding-top: 1.5em; }

  .vjs-volume-bar.vjs-slider-horizontal {
    width: 3em; }

  .vjs-volume-panel {
    width: 3em;

    &.vjs-volume-panel-horizontal:hover, &:active .vjs-volume-control.vjs-volume-horizontal, &:focus .vjs-volume-control.vjs-volume-horizontal {
      width: 3em; }

    &:hover {
      width: 3em;

      .vjs-volume-control.vjs-volume-horizontal {
        width: 3em; } } }

  .vjs-volume-level:before {
    font-size: 1em; }

  .vjs-volume-panel .vjs-volume-control {
    opacity: 1;
    width: 100%;
    height: 100%; }

  .vjs-volume-bar {
    background-color: transparent;
    margin: 0; }

  .vjs-slider-horizontal .vjs-volume-level {
    height: 100%; }

  .vjs-volume-bar {
    &.vjs-slider-horizontal {
      margin-top: 0;
      margin-bottom: 0;
      height: 100%; }

    &:before {
      content: "";
      z-index: 0;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-color: transparent transparent hsla(0, 0%, 100%, 0.25);
      border-style: solid;
      border-width: 0 0 1.75em 3em; } }

  .vjs-volume-level {
    overflow: hidden;
    background-color: transparent;

    &:before {
      content: "";
      z-index: 1;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-left: 3em solid transparent;
      border-bottom: 1.75em solid var(--vjs-theme-city--secondary);
      border-right: 0 solid transparent;
      border-top: 0 solid transparent; } } ; }

.vjs_video_3-dimensions {
  width: 100%;
  height: auto; }

.strong, strong {
  font-weight: 700; }

input, textarea {
  appearance: none;
  resize: none; }
  input::placeholder, textarea::placeholder {
    font-size: 11px;
    line-height: 1.3;
    color: white; }
    @media (max-width: 730px) {
      input::placeholder, textarea::placeholder {
        font-size: 11.5px;
        padding-top: 5px; } }

@media (hover: hover) {
  html, body {
    scroll-snap-type: y mandatory;
    scroll-padding: 0px 0px 0px 0px;
    position: relative;
    overscroll-behavior: none; }
    html .director, html .reel, html header, html footer, html .newsletter, html .reel-grid, html .reel-block, html .attachment-block, body .director, body .reel, body header, body footer, body .newsletter, body .reel-grid, body .reel-block, body .attachment-block {
      scroll-snap-align: start;
      scroll-snap-stop: always; } }

@media (hover: hover) and (max-width: 730px) {
  .bottom-logo {
    scroll-snap-align: start;
    scroll-snap-stop: always; } }

body {
  font-weight: 300;
  overflow-x: hidden; }
  body * {
    box-sizing: border-box; }

body > div:first-of-type > p, body > div:first-of-type > br {
  display: none; }

.container, .hero, .newsletter .container, .content {
  width: 100%;
  padding: 0 100px; }
  @media (max-width: 1019px) {
    .container, .hero, .newsletter .container, .content {
      padding: 0 20px; } }

p {
  padding-bottom: 30px;
  line-height: 1.3;
  font-size: 1.1em; }

body {
  font-family: 'Adieu';
  -webkit-font-smoothing: antialiased;
  background: #161616;
  color: white; }
  body .icon-landia svg {
    fill: white; }
  body .logo-landia .st0 {
    fill: white; }
  body .logo-landia .st1 {
    fill: none;
    stroke: white;
    stroke-width: 4;
    stroke-miterlimit: 10; }
  body header, body .reel-header {
    background: #161616;
    z-index: 999999; }
  body header {
    border-bottom: 1px solid white; }
  body .reel-header {
    border-top: 1px solid white; }

header, .reel-header {
  padding: 8px 0px;
  display: flex; }
  @media (max-width: 1019px) {
    header p, header h2, .reel-header p, .reel-header h2 {
      font-size: .9em; } }
  header .container, .reel-header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    position: relative; }
    header .container p, .reel-header .container p {
      height: auto; }
  header .header-text, .reel-header .header-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px; }
  header .icon-landia svg, .reel-header .icon-landia svg {
    height: 35px;
    width: auto; }
  header p, .reel-header p {
    padding-bottom: 0; }
  @media (max-width: 1019px) {
    header, .reel-header {
      justify-content: flex-start;
      align-items: center; } }

@media (max-width: 1019px) {
  header .header-text {
    display: block; } }

.reel-header .header-text {
  padding-right: 0; }
  .reel-header .header-text p {
    padding-right: 10px;
    font-size: .75em;
    letter-spacing: 2px; }
  .reel-header .header-text h2 {
    line-height: 1.3; }

@media (max-width: 1019px) {
  .reel-header .icon-landia {
    display: none; }
  .reel-header .header-text p {
    font-size: .6em;
    min-width: 110px;
    text-align: right;
    padding-right: 0; } }

@media (max-width: 730px) {
  .reel-header .header-text {
    align-items: flex-start; }
    .reel-header .header-text p {
      padding-top: 6px;
      font-size: .5em;
      letter-spacing: 0px; } }

.video-hero {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; }
  @media (max-width: 730px) {
    .video-hero {
      height: 50vh; } }

.hero {
  background-position: center center;
  background-size: cover;
  position: relative;
  height: calc(100vh - 60px);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 730px) {
    .hero {
      height: calc(50vh - 77px); } }
  .hero svg {
    width: 100%;
    height: auto;
    background: none; }
    .hero svg .st0 {
      fill: #fff; }
    .hero svg .st1 {
      fill: none;
      stroke: #fff;
      stroke-width: 4;
      stroke-miterlimit: 10; }

.director {
  background: #161616;
  position: relative;
  border-bottom: 1px solid white; }
  .director h1 {
    font-size: 5.5vw;
    font-weight: 900;
    padding: 30px 0 40px 0;
    line-height: 1;
    position: relative; }
    @media (max-width: 1019px) {
      .director h1 {
        padding: 30px 90px 40px 0; }
        .director h1 .btn-round {
          right: 0;
          transform: scale(0.8) translateY(-69%); } }
    @media (max-width: 730px) {
      .director h1 {
        font-size: 11.5vw; }
        .director h1 .btn-round {
          right: 0;
          top: 58px;
          transform: scale(0.8) translateY(-69%); } }

.section-spacer {
  height: 100px;
  background: #161616; }
  @media (max-width: 730px) {
    .section-spacer {
      display: none; } }

.profile {
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 150px - 100px);
  color: white; }
  @media (max-width: 729px) {
    .profile {
      height: 0px; } }
  .profile .profile-description {
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 0;
    position: relative;
    opacity: 0; }
    .profile .profile-description p {
      width: 60%;
      font-size: 1.1vw;
      line-height: 1.4; }
    .profile .profile-description.show {
      height: calc(100vh - 150px - 100px);
      opacity: 1;
      transition: all, 0.3s; }
      @media (max-width: 1019px) {
        .profile .profile-description.show {
          position: absolute;
          z-index: 99999999;
          background-color: rgba(0, 0, 0, 0.9);
          height: auto; }
          .profile .profile-description.show p {
            width: 100%;
            font-size: 0.9em; } }

.btn-round {
  width: 84px;
  height: 68px;
  margin-left: 30px;
  border-radius: 25px;
  background-color: #161616;
  position: relative;
  border: 2px solid white;
  cursor: pointer;
  padding: 20px 28px;
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%); }
  .btn-round:hover {
    background-color: #161616; }
  .btn-round:focus {
    outline: none; }
  .btn-round.cross .close:after {
    transform: rotate(90deg) translateX(-420%); }
  @media (max-width: 1019px) {
    .btn-round {
      position: absolute; } }

.close {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all, 0.3s; }
  .close::before, .close::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    transition: all, 0.3s; }
  .close:before {
    height: 3px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%); }
  .close:after {
    height: 100%;
    width: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%); }

.reel {
  padding-bottom: 100px;
  background: #161616; }
  @media (max-width: 730px) {
    .reel {
      padding-bottom: 20px; } }

.reel-video {
  max-height: calc(100vh - 100px - 77px);
  overflow: hidden; }
  @media (max-width: 730px) {
    .reel-video {
      max-height: calc(100vh - 100px - 20px); } }

@media (min-width: 1200px) {
  .reel-video-playing .click-dimensions.vjs-fluid {
    padding: 0 !important;
    height: 100vh; }
  .reel-video-playing .video-js .vjs-fluid {
    height: 100%; } }

@media (max-width: 1199px) {
  .reel-video-playing .click-dimensions.vjs-fluid {
    height: 100vh; }
  .reel-video-playing .video-js .vjs-fluid {
    height: 100%; } }

@media (min-width: 1200px) {
  .reel-video-paused .video-js .vjs-tech {
    top: -18%; } }

@media (max-width: 1199px) {
  .reel-video-paused .video-js .vjs-tech {
    top: -10%; } }

@media (max-width: 1019px) {
  .reel-video-paused .video-js .vjs-tech {
    top: 0; } }

.video-js.focus {
  outline: none; }

.video-js .vjs-control-bar {
  background: rgba(0, 0, 0, 0.5); }

.video-js .vjs-load-progress {
  background: none; }
  .video-js .vjs-load-progress div {
    background: rgba(255, 255, 255, 0.5); }

.reel-grid {
  background: #161616;
  border-top: 1px solid white;
  position: relative;
  z-index: 9;
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px; }
  @media (max-width: 1019px) {
    .reel-grid {
      display: none; } }
  .reel-grid .reel-block h3 {
    text-align: center;
    padding: 20px;
    font-size: .9em;
    opacity: 0;
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    transition: all .3s;
    line-height: 1.5;
    width: 80%; }
    .reel-grid .reel-block h3 .strong, .reel-grid .reel-block h3 strong {
      display: block; }
  .reel-grid .reel-block a {
    position: relative;
    display: block;
    max-height: 35vh;
    overflow: hidden;
    color: white; }
    .reel-grid .reel-block a::after {
      content: "";
      background: rgba(0, 0, 0, 0);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: all .3s; }
    .reel-grid .reel-block a:hover::after {
      background: rgba(0, 0, 0, 0.7); }
    .reel-grid .reel-block a:hover h3 {
      opacity: 1;
      z-index: 99; }
    .reel-grid .reel-block a img {
      display: block;
      width: 100%;
      height: auto; }
  .reel-grid.columns-one-third .reel-block {
    width: 33.33%; }
  .reel-grid.columns-one-half .reel-block {
    width: 50%; }
  .reel-grid.columns-one-quarter .reel-block {
    width: 25%; }

.attachment-grid {
  background: #161616;
  position: relative;
  z-index: 9;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  border-bottom: 1px solid white; }
  .attachment-grid .attachment-block {
    width: 33.33%;
    padding-bottom: 40px; }
    @media (max-width: 1019px) {
      .attachment-grid .attachment-block {
        width: 100%; } }
    .attachment-grid .attachment-block h3 {
      text-align: center;
      padding: 20px; }
    .attachment-grid .attachment-block img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 200px;
      margin: 0 auto; }
  @media (max-width: 1019px) {
    .attachment-grid {
      display: block; } }

.bottom-logo, .footer-post {
  background: #161616; }
  .bottom-logo .icon-landia, .footer-post .icon-landia {
    padding: 80px 0 70px 0;
    text-align: center; }
    .bottom-logo .icon-landia svg, .footer-post .icon-landia svg {
      height: 70px;
      width: auto; }
    @media (max-width: 730px) {
      .bottom-logo .icon-landia, .footer-post .icon-landia {
        padding: 30px 0 20px 0; }
        .bottom-logo .icon-landia svg, .footer-post .icon-landia svg {
          height: 40px; } }

footer {
  background: #161616;
  text-align: center; }
  footer .container {
    max-width: 880px;
    margin: 0 auto; }
    footer .container h3 {
      font-size: 1.55em;
      padding-top: 80px;
      margin-bottom: 10px; }
    footer .container h4 {
      font-size: 2.5em;
      font-weight: 900;
      margin-bottom: 170px; }
    @media (max-width: 730px) {
      footer .container h3 {
        font-size: 1.3em;
        padding-top: 20px; }
      footer .container h4 {
        font-size: 1.8em;
        margin-bottom: 50px; } }
  footer .logo-landia .st1 {
    fill: white; }

.newsletter {
  background: #161616;
  padding-top: 150px;
  padding-bottom: 100px; }
  .newsletter .container {
    max-width: 880px;
    margin: 0 auto; }
    .newsletter .container h3 {
      font-size: 1.5em;
      font-weight: 700; }
    .newsletter .container form {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 1019px) {
        .newsletter .container form {
          display: block; } }
    .newsletter .container input, .newsletter .container textarea {
      background: none;
      border: none;
      border-bottom: 1px solid white;
      padding: 20px 0;
      width: 64%;
      font-size: 14px;
      color: white;
      height: 55px;
      border-radius: 0;
      font-family: 'Adieu';
      font-weight: 300; }
      @media (max-width: 1019px) {
        .newsletter .container input, .newsletter .container textarea {
          width: 100%; } }
      .newsletter .container input:focus, .newsletter .container textarea:focus {
        outline: none;
        border-bottom: 3px solid white; }
    .newsletter .container button {
      background: none;
      border-radius: 0;
      border: 1px solid white;
      color: white;
      width: 33%;
      height: 55px;
      text-transform: uppercase; }
      @media (max-width: 1019px) {
        .newsletter .container button {
          width: 100%;
          margin-top: 20px; } }

.design-2 .hero {
  height: calc(50vh - 60px); }
  @media (max-width: 730px) {
    .design-2 .hero {
      height: calc(50vh - 77px); } }
  .design-2 .hero svg .st0 {
    fill: white; }
  .design-2 .hero svg .st1 {
    fill: white;
    stroke: white; }

.design-2.light-mode .hero svg .st0 {
  fill: black; }

.design-2.light-mode .hero svg .st1 {
  fill: black;
  stroke: black; }

.video-js .vjs-big-play-button {
  display: none !important; }

.video-js .vjs-control-bar {
  background: rgba(0, 0, 0, 0.5) !important; }

.video-js .vjs-slider {
  background: rgba(255, 255, 255, 0.3) !important; }

.light-mode {
  background: #f7f7f7;
  color: black; }
  .light-mode .icon-landia svg {
    fill: black; }
  .light-mode .attachment-grid {
    background: #f7f7f7;
    border-bottom: 1px solid black; }
  .light-mode header, .light-mode .reel-header {
    background: #f7f7f7; }
  .light-mode header {
    border-bottom: 1px solid black; }
  .light-mode .director {
    background: #f7f7f7; }
  .light-mode .section-spacer {
    background: #f7f7f7; }
  .light-mode .btn-round {
    background-color: #f7f7f7;
    border: 4px solid black; }
  .light-mode .close::before, .light-mode .close::after {
    background: black; }
  .light-mode .reel-header {
    border-top: 1px solid black; }
  .light-mode .reel {
    background: #f7f7f7; }
  .light-mode .reel-grid {
    background: #f7f7f7;
    border-top: 1px solid black; }
  .light-mode input::placeholder, .light-mode textarea::placeholder {
    color: black; }
  .light-mode .bottom-logo, .light-mode footer, .light-mode .newsletter, .light-mode .footer-post {
    background: #f7f7f7;
    color: black; }
  .light-mode .newsletter .container input, .light-mode .newsletter .container textarea {
    border-bottom: 1px solid black;
    color: black; }
    .light-mode .newsletter .container input::placeholder, .light-mode .newsletter .container textarea::placeholder {
      color: black; }
  .light-mode .newsletter .container button {
    border: 1px solid black;
    color: black; }
  .light-mode footer .logo-landia .st0 {
    fill: black; }
  .light-mode footer .logo-landia .st1 {
    fill: black;
    stroke: black; }

.logo-landia-post svg {
  width: 200px;
  height: auto;
  fill: white;
  padding: 10px 0; }

.project-title {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%); }

.content {
  color: white;
  padding-top: 40px; }

.plain-reel a:hover {
  text-decoration: none; }

.plain-reel .container {
  padding: 0 65px; }
  @media (max-width: 730px) {
    .plain-reel .container {
      padding: 0; } }

@media (max-width: 730px) {
  .plain-reel .header {
    border-bottom: none;
    padding-bottom: 0; } }

.plain-reel .header .container {
  flex-wrap: wrap; }

@media (max-width: 730px) {
  .plain-reel .header {
    text-align: center; }
    .plain-reel .header .icon-landia {
      display: none; } }

.plain-reel .header-text {
  width: calc(75% - 35px); }
  @media (max-width: 730px) {
    .plain-reel .header-text {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .plain-reel .header-text p, .plain-reel .header-text h1 {
        width: 100%;
        padding: 10px 0; }
      .plain-reel .header-text h1 {
        order: -1; }
      .plain-reel .header-text p {
        font-size: .7em !important; }
      .plain-reel .header-text span {
        font-size: .4em !important; } }
  .plain-reel .header-text h1 {
    font-size: 1.3em; }
  .plain-reel .header-text p {
    text-transform: uppercase;
    font-size: .6em;
    color: #666; }
    .plain-reel .header-text p a {
      color: #666;
      letter-spacing: 1px;
      padding: 3px;
      position: relative; }
      .plain-reel .header-text p a span {
        position: absolute;
        background: white;
        top: 100%;
        position: absolute;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        padding: 5px;
        border-radius: 3px;
        color: black;
        z-index: 999;
        opacity: 0;
        transition: opacity .3s; }
        .plain-reel .header-text p a span::before {
          content: "";
          position: absolute;
          top: -10px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent #fff transparent; }
        .plain-reel .header-text p a span.active {
          opacity: 1; }

.plain-reel .top-logo {
  display: flex;
  align-items: center;
  width: 25%; }
  @media (max-width: 730px) {
    .plain-reel .top-logo {
      width: 100%;
      border-bottom: 1px solid white; } }
  .plain-reel .top-logo svg {
    height: 35px;
    width: auto;
    margin-right: 50px;
    max-width: 80%; }
    @media (max-width: 730px) {
      .plain-reel .top-logo svg {
        margin: 0 auto;
        height: auto;
        width: 70%;
        padding: 10px 0 20px 0; } }
    .plain-reel .top-logo svg .st0 {
      fill: #fff; }
    .plain-reel .top-logo svg .st1 {
      fill: #fff; }

.reels-container {
  display: flex;
  height: calc( 100vh - 60px);
  padding-top: 30px !important;
  overflow: hidden;
  justify-content: space-between; }
  @media (max-width: 730px) {
    .reels-container {
      flex-wrap: wrap;
      height: auto;
      padding-top: 10px !important; } }
  .reels-container .reel-list {
    width: 14%;
    min-width: 160px;
    height: calc( 100vh - 190px);
    overflow: hidden;
    position: relative; }
    @media (max-width: 1019px) {
      .reels-container .reel-list {
        min-width: 1px; } }
    @media (max-width: 730px) {
      .reels-container .reel-list {
        width: 100%;
        height: auto;
        flex-grow: stretch; }
        .reels-container .reel-list span {
          display: block; } }
    .reels-container .reel-list::after {
      content: '';
      background: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0) 100%);
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: -10px; }
    .reels-container .reel-list h2 {
      margin-bottom: 20px;
      font-size: .8em;
      letter-spacing: 1px;
      position: relative;
      display: flex; }
      @media (max-width: 730px) {
        .reels-container .reel-list h2 {
          display: none; } }
      .reels-container .reel-list h2 svg {
        position: relative;
        margin-left: 3px;
        top: -3px; }
    .reels-container .reel-list ul {
      height: calc( 100vh - 220px);
      overflow: auto;
      counter-reset: items; }
      @media (max-width: 1019px) {
        .reels-container .reel-list ul {
          height: 200px; } }
      .reels-container .reel-list ul::-webkit-scrollbar {
        width: 12px; }
      .reels-container .reel-list ul::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #474747; }
      .reels-container .reel-list ul::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #161616;
        border: 3px solid #474747; }
      .reels-container .reel-list ul li {
        margin-bottom: 10px;
        margin-right: 10px;
        counter-increment: items; }
        @media (max-width: 1019px) {
          .reels-container .reel-list ul li {
            margin: 0; } }
        .reels-container .reel-list ul li:last-of-type {
          margin-bottom: 0; }
      .reels-container .reel-list ul img {
        opacity: .5; }
      .reels-container .reel-list ul a {
        color: #4a4a4a;
        font-weight: 600;
        font-size: 1em; }
        .reels-container .reel-list ul a.active {
          color: white; }
          .reels-container .reel-list ul a.active img {
            border: 1px solid white;
            opacity: 1; }
        @media (max-width: 730px) {
          .reels-container .reel-list ul a {
            display: flex;
            align-items: center; }
            .reels-container .reel-list ul a img {
              width: 100px; } }
        @media (max-width: 730px) {
          .reels-container .reel-list ul a.active img {
            opacity: 1;
            outline: 0; } }
      .reels-container .reel-list ul span {
        display: none; }
        @media (max-width: 730px) {
          .reels-container .reel-list ul span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 10px; } }
        .reels-container .reel-list ul span::before {
          content: counter(items) ". "; }
    .reels-container .reel-list .arrow-down {
      height: 20px;
      fill: #FFF; }
  .reels-container .reel-carousel {
    width: 75%;
    position: relative;
    height: calc( 100vh - 190px); }
    @media (max-width: 730px) {
      .reels-container .reel-carousel {
        order: -1;
        width: 100%;
        height: auto; }
        .reels-container .reel-carousel #theCarousel {
          display: none; } }
    .reels-container .reel-carousel h2 {
      text-align: right;
      padding-top: 20px; }

.the-player {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: calc( 100vh - 190px); }

video, .video-js {
  width: 100%;
  height: calc( 100vh - 190px);
  position: absolute;
  background: #161616; }

.vjs-poster {
  background-size: cover !important; }

.video-js + img {
  display: none !important; }

@media (min-width: 1200px) {
  .vjs-has-started .vjs-control-bar {
    display: none !important; }
  .vjs-has-started:hover .vjs-control-bar {
    display: flex !important; } }

a.active {
  color: white !important; }

.plain-profile {
  height: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 40px 50px;
  position: absolute;
  z-index: 999999;
  transition: all, .3s;
  overflow: hidden;
  top: 0; }
  .plain-profile.show {
    height: 100%;
    opacity: 1; }
  .plain-profile p {
    font-size: 1.15vw;
    line-height: 1.6;
    max-width: 70%; }
  @media (max-width: 730px) {
    .plain-profile p {
      font-size: .8em;
      max-width: 100%; }
    .plain-profile.show {
      overflow: auto; }
      .plain-profile.show::-webkit-scrollbar {
        width: 12px; }
      .plain-profile.show::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background: #474747; }
      .plain-profile.show::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #161616;
        border: 3px solid #474747; } }

*:focus {
  outline: none !important; }

.video-mobile {
  display: none;
  width: 100%; }
  @media (max-width: 730px) {
    .video-mobile {
      display: block; } }
